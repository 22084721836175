<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
-->
<div class="bg-white">
    <div class="pb-16 pt-6 sm:pb-24">
        <div class="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs" class="pb-8 block"
                [linkLast]="true"></vsf-collection-breadcrumbs>
            <div class="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
                <div class="lg:col-span-5 lg:col-start-8">
                    <div class="flex justify-between">
                        <h2 *ngIf="product; else titlePlaceholder" class="text-2xl sm:text-3xl  text-gray-900 ">
                            {{ product?.name ?? ' ' }}
                        </h2>
                        <ng-template #titlePlaceholder>
                            <div class="h-8 w-72 bg-gray-200 animate-pulse ">
                                {{ product?.name ?? ' ' }}
                            </div>
                        </ng-template>

                        <p class="text-xl font-medium text-gray-900"> {{ selectedVariant?.priceWithTax | formatPrice }}
                        </p>
                    </div>

                </div>

                <!-- Image gallery -->
                <div class="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                    <h2 class="sr-only">Images</h2>

                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8">

                        <vsf-asset-gallery [assets]="product?.assets"
                            [selectedAssetId]="product?.featuredAsset?.id"></vsf-asset-gallery>
                    </div>
                </div>

                <div class="mt-4 lg:col-span-5" #zoomPreviewArea>
                    <form>
                        <!-- Size picker -->
                        <div class="mt-8">
                            <div class="flex items-center justify-between">
                                <h2 class="text-sm font-medium text-gray-900">Size</h2>

                            </div>

                            <fieldset *ngIf="product?.variants.length > 1" aria-label="Choose a size" class="mt-2">
                                <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
                                    <!--
                      In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                      Active: "ring-2 ring-indigo-500 ring-offset-2"
                      Checked: "border-transparent bg-indigo-600 text-white hover:bg-indigo-700", 
                      Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
                    -->
                                    <label *ngFor="let variant of product?.variants"
                                        [class.out-of-stock]="variant.stockLevel === 'OUT_OF_STOCK'"
                                        [class.in-stock]="variant.stockLevel === 'IN_STOCK'"
                                        [class.active]="variant.id === selectedVariant.id"
                                        class="product-detail-status product-detail-stock flex cursor-pointer items-center justify-center rounded-md border px-3 py-3 text-xs font-medium uppercase focus:outline-none sm:flex-1">
                                        <input [(ngModel)]="selectedVariant" [ngModelOptions]="{standalone: true}"
                                            type="radio" name="size-choice" [value]="variant" class="sr-only"
                                            (change)="selectVariant(variant)"
                                            [disabled]="variant.stockLevel === 'OUT_OF_STOCK'">

                                        <span>{{ variant.name }} </span>
                                    </label>
                                </div>
                            </fieldset>
                        </div>

                        <button type="submit"
                            class="mt-8 flex w-full transition-colors border border-transparent rounded-md py-3 px-8  items-center justify-center text-base font-medium text-white bg-primary-600  hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-full"
                            [ngClass]="{
                            'bg-gray-400': inFlight,
                            'bg-primary-600 hover:bg-primary-700': !inFlight
                        }" (click)="addToCart(selectedVariant, qty)">
                            <div *ngIf="!product || !qtyInCart[selectedVariant.id]; else inCartCount">Add to cart</div>
                            <ng-template #inCartCount>
                                <fa-icon icon="check" class="mr-2"></fa-icon>
                                <span>{{ qtyInCart[selectedVariant.id] }} in cart</span>
                            </ng-template>
                        </button>


                    </form>

                    <!-- Product details -->
                    <div class="mt-10">
                        <h2 class="text-sm font-medium text-gray-900">Description</h2>

                        <div class="prose prose-sm mt-4 text-gray-500">
                            <div class="text-base text-gray-700" [innerHTML]="product?.description | safeHtml"></div>
                        </div>
                    </div>



                    <!-- Policies -->
                    <!-- <section aria-labelledby="policies-heading" class="mt-10">
                        <h2 id="policies-heading" class="sr-only">Our Policies</h2>

                        <dl class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                            <div class="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
                                <dt>
                                    <svg class="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" fill="none"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                                    </svg>
                                    <span class="mt-4 text-sm font-medium text-gray-900">International delivery</span>
                                </dt>
                                <dd class="mt-1 text-sm text-gray-500">Get your order in 2 years</dd>
                            </div>
                            <div class="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
                                <dt>
                                    <svg class="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" fill="none"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span class="mt-4 text-sm font-medium text-gray-900">Loyalty rewards</span>
                                </dt>
                                <dd class="mt-1 text-sm text-gray-500">Don&#039;t look at other tees</dd>
                            </div>
                        </dl>
                    </section> -->
                </div>
            </div>
        </div>
    </div>
</div>





<ng-template #addedToCartTemplate let-variant="variant" let-quantity="quantity" let-close="closeFn">
    <div class="flex">
        <div class="mr-8">
            <img class="rounded"
                [src]="(variant.featuredAsset?.preview || product?.featuredAsset?.preview) + '?preset=tiny'"
                alt="product thumbnail" />
        </div>
        <div class="text-sm">{{ quantity }} x {{ variant.name }}</div>
    </div>
    <div class="flex justify-end">
        <button type="button" (click)="viewCartFromNotification(close)"
            class="inline-flex items-center rounded border border-transparent bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            View cart
        </button>
    </div>
</ng-template>