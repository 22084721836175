import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SearchProductsQuery } from '../../../common/generated-types';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'vsf-product-card',
    templateUrl: './product-card.component.html',
    // styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent {
    sanitizedDescription: SafeHtml;

    @Input() show_description: boolean = true;
    @Input() product: SearchProductsQuery['search']['items'][number];

    ngOnInit() {
        this.sanitizedDescription = this.sanitizeHtml(this.product.description);
    }
    constructor(private sanitizer: DomSanitizer) { }
    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
