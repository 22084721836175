<ng-container *ngIf="collectionTree$ | async as tree">
    <div class="relative">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-4 gap-x-8 gap-y-10 py-16">

                <div class="group relative" *ngFor="let collection of tree.children">
                    <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                        <img src="{{ collection.featuredAsset?.preview }}"
                            alt="Models sitting back to back, wearing Basic Tee in black and bone."
                            class="object-cover object-center">
                    </div>
                    <a [routerLink]="['/category/', collection.slug]" class="mt-4 block font-medium text-gray-900">
                        <span class="absolute inset-0 z-10" aria-hidden="true"></span>
                        {{ collection.name }}
                    </a>
                    <p aria-hidden="true" class="mt-1">Shop now</p>
                </div>


            </div>

        </div>
    </div>





</ng-container>




<ng-template #menuTemplate>
    <div class="collections-menu-sub-collections" *ngIf="activeCollection?.children?.length"
        (touchstart)="captureTouchStart($event)" (mouseenter)="onMouseEnter(activeCollection)" (mouseleave)="close()">
        <div class="container d-flex flex-wrap">
            <div class="level-1-block" *ngFor="let level1 of activeCollection.children">
                <vsf-collection-card [collection]="level1"></vsf-collection-card>
                <ng-container *ngFor="let level2 of level1.children">
                    <a class="level-2-link" [routerLink]="['/category', level2.slug]" (click)="close()">
                        {{ level2.name }}
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>