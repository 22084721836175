import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

import { GetCollectionQuery } from '../../../common/generated-types';
import { AssetPreviewPipe } from '../../pipes/asset-preview.pipe';

@Component({
    selector: 'vsf-collection-card',
    templateUrl: './collection-card.component.html',
    // styleUrls: ['./collection-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCardComponent implements OnChanges {
    @Input() collection: NonNullable<GetCollectionQuery['collection']>;

    @Input() show_description: boolean = true;

    backgroundImage: SafeStyle;
    sanitizedDescription: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.sanitizedDescription = this.sanitizeHtml(this.collection.description);
    }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    ngOnChanges() {
        if (this.collection.featuredAsset) {
            const assetPreviewPipe = new AssetPreviewPipe();
            this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
                `url('${assetPreviewPipe.transform(this.collection.featuredAsset, 400, 150)}')`,
            );
        }
    }

}
