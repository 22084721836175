<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
    <a class="btn btn-light text-muted" [routerLink]="['/account']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-sm-inline ml-1">{{ userName(customer) }}</span>
    </a>
</div>
<ng-template #notLoggedIn>
    <a class="btn btn-light text-muted" [routerLink]="['account/sign-in']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-sm-inline ml-1 ">Sign In</span></a>
    <a href="/account/register" class="text-sm ml-4 font-medium text-white hover:text-gray-100">Create an account</a>
</ng-template>