<ng-container *ngIf="collectionTree$ | async as tree">



    <div
        class="mx-auto grid max-w-md grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:grid-cols-4 sm:gap-x-6 sm:gap-y-0 sm:px-6 lg:gap-x-8 lg:px-8">
        <div *ngFor="let collection of tree.children"
            class="group relative h-96 rounded-lg bg-white shadow-xl sm:aspect-h-5 sm:aspect-w-4 ">
            <div>

                <div aria-hidden="true" class="absolute inset-0 overflow-hidden rounded-lg">

                    <a [routerLink]="['/category/', collection.slug]"
                        class="absolute inset-0 overflow-hidden group-hover:opacity-75">
                        <img src="{{ collection.featuredAsset?.preview }}"
                            alt="Woman wearing a comfortable cotton t-shirt."
                            class="h-full w-full object-cover object-center">
                    </a>


                </div>


                <div class="absolute inset-0 flex items-end rounded-lg ">
                    <a class="text-lg mx-auto w-full" [routerLink]="['/category/', collection.slug]">
                        <div
                            class="bg-primary-500 w-full opacity-90 hover:bg-primary-300 mx-auto rounded-lg rounded-t-none px-4 py-2">
                            <p aria-hidden="true" class="text-sm w text-white text-center">Shop the collection</p>
                            <h3 class="font-semibold text-center text-white ">

                                <span class=" absoluteinset-0"></span>
                                {{ collection.name }}

                            </h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>



</ng-container>





<ng-template #menuTemplate>
    <div class="collections-menu-sub-collections" *ngIf="activeCollection?.children?.length"
        (touchstart)="captureTouchStart($event)" (mouseenter)="onMouseEnter(activeCollection)" (mouseleave)="close()">
        <div class="container d-flex flex-wrap">
            <div class="level-1-block" *ngFor="let level1 of activeCollection.children">
                <vsf-collection-card [collection]="level1"></vsf-collection-card>
                <ng-container *ngFor="let level2 of level1.children">
                    <a class="level-2-link" [routerLink]="['/category', level2.slug]" (click)="close()">
                        {{ level2.name }}
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>