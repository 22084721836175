<!-- <ng-template [ngIf]="product">
    <a class="flex flex-col" prefetch='intent' [routerLink]="['/product', product.slug]">
        <img class="rounded-xl flex-grow object-cover aspect-[7/8]" alt=""
            [src]="product.productAsset | assetPreview:300:400" />
        <div class="h-2"></div>
        <div class="text-sm text-gray-700">
            {{ product.productName }}
        </div>
        <div class="text-sm font-medium text-gray-900">
            <ng-template [ngIf]="product.priceWithTax.min === product.priceWithTax.max" [ngIfElse]="range">
                {{ product.priceWithTax.min | formatPrice }}
            </ng-template>
            <ng-template #range>
                From {{ product.priceWithTax.min | formatPrice }}
            </ng-template>
        </div>
    </a>
</ng-template> -->

<ng-template [ngIf]="product">
    <div class="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div class="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
            <img [src]="product.productAsset | assetPreview:300:400" alt="An image describing {{ product.productName }}"
                class="h-full w-full object-cover object-center sm:h-full sm:w-full">
        </div>
        <div class="flex flex-1 flex-col space-y-2 p-4">
            <h3 class="text-sm font-medium text-gray-900">
                <a prefetch='intent' [routerLink]="['/product', product.slug]">
                    <span aria-hidden="true" class="absolute inset-0"></span>
                    {{ product.productName }}
                </a>
            </h3>

            <div class="text-sm text-gray-500" [innerHTML]="sanitizedDescription"></div>

            <div class="flex flex-1 flex-col justify-end">
                <p *ngIf="product.variants?.length" class="text-sm italic text-gray-500">{{ product.variants?.length }}
                    sizes</p>
                <p class="text-base font-medium text-gray-900"> <ng-template
                        [ngIf]="product.priceWithTax.min === product.priceWithTax.max" [ngIfElse]="range">
                        {{ product?.priceWithTax.min | formatPrice }}
                    </ng-template>
                    <ng-template #range>
                        From {{ product?.priceWithTax.min | formatPrice }}
                    </ng-template>
                </p>
            </div>
        </div>
    </div>
</ng-template>