<main>
    <!-- Hero section -->
    <div class="relative">
        <!-- Background image and overlap -->
        <div aria-hidden="true" class="absolute inset-0 hidden sm:flex sm:flex-col">
            <div class="relative w-full flex-1 bg-gray-800">
                <div class="absolute inset-0 overflow-hidden">
                    <img src="https://shop.rovercloudspace.com/assets/preview/a3/delia_homepage_bg2__preview.jpg?preset=large"
                        alt="" class="h-full w-full object-cover object-center">
                </div>
                <div class="absolute inset-0 bg-gray-900 opacity-50"></div>
            </div>
            <div class="h-32 w-full bg-white md:h-40 lg:h-48"></div>
        </div>

        <div class="relative mx-auto max-w-3xl px-4 pb-96 text-center sm:px-6 sm:pb-0 lg:px-8">
            <!-- Background image and overlap -->
            <div aria-hidden="true" class="absolute inset-0 flex flex-col sm:hidden">
                <div class="relative w-full flex-1 bg-gray-800">
                    <div class="absolute inset-0 overflow-hidden">
                        <img src="https://shop.rovercloudspace.com/assets/preview/a3/delia_homepage_bg2__preview.jpg?preset=large"
                            alt="" class="h-full w-full object-cover object-center">
                    </div>
                    <div class="absolute inset-0 bg-gray-900 opacity-50"></div>
                </div>
                <div class="h-48 w-full bg-white">Featured Products</div>

            </div>
            <div class="relative py-32">
                <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">Shop featured products
                </h1>

                <div class="mt-4 sm:mt-6">
                    <a href="/category/featured"
                        class="inline-block rounded-md border border-transparent bg-primary-500 px-8 py-3 font-medium text-white hover:bg-primary-300">Shop
                        Collection</a>
                </div>
            </div>
        </div>

        <section aria-labelledby="collection-heading" class="relative -mt-96 sm:mt-0">
            <h2 id="collection-heading" class="sr-only">Collections</h2>

            <vsf-collections-home class=" text-gray-600"></vsf-collections-home>

        </section>
    </div>

    <section aria-labelledby="trending-heading">
        <div class="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8 lg:pt-32">
            <div class="md:flex md:items-center md:justify-between">
                <h2 id="favorites-heading" class="text-2xl font-bold tracking-tight text-gray-900">Trending Products
                </h2>
                <a href="/category/featured"
                    class="hidden text-sm font-medium text-primary-500 hover:text-primary-300 md:block">
                    Shop the collection
                    <span aria-hidden="true"> &rarr;</span>
                </a>
            </div>

            <div class="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
                <vsf-product-card *ngFor="let product of products$ | async; trackBy: trackByProductId"
                    [product]="product"></vsf-product-card>


            </div>

            <div class="mt-8 text-sm md:hidden">
                <a href="/category/featured" class="font-medium text-primary-500 hover:text-primary-300 ">
                    Shop the collection
                    <span aria-hidden="true"> &rarr;</span>
                </a>
            </div>
        </div>


    </section>

    <!-- <section aria-labelledby="perks-heading" class="border-t border-gray-200 bg-gray-50">
        <h2 id="perks-heading" class="sr-only">Our perks</h2>

        <div class="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div class="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0">
                <div class="text-center md:flex md:items-start md:text-left lg:block lg:text-center">
                    <div class="md:flex-shrink-0">
                        <div class="flow-root">
                            <img class="-my-1 mx-auto h-24 w-auto"
                                src="https://tailwindui.com/img/ecommerce/icons/icon-returns-light.svg" alt="">
                        </div>
                    </div>
                    <div class="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                        <h3 class="text-base font-medium text-gray-900">Free returns</h3>
                        <p class="mt-3 text-sm text-gray-500">Not what you expected? Place it back in the parcel and
                            attach the pre-paid postage stamp.</p>
                    </div>
                </div>
                <div class="text-center md:flex md:items-start md:text-left lg:block lg:text-center">
                    <div class="md:flex-shrink-0">
                        <div class="flow-root">
                            <img class="-my-1 mx-auto h-24 w-auto"
                                src="https://tailwindui.com/img/ecommerce/icons/icon-calendar-light.svg" alt="">
                        </div>
                    </div>
                    <div class="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                        <h3 class="text-base font-medium text-gray-900">Same day delivery</h3>
                        <p class="mt-3 text-sm text-gray-500">We offer a delivery service that has never been done
                            before. Checkout today and receive your products within hours.</p>
                    </div>
                </div>
                <div class="text-center md:flex md:items-start md:text-left lg:block lg:text-center">
                    <div class="md:flex-shrink-0">
                        <div class="flow-root">
                            <img class="-my-1 mx-auto h-24 w-auto"
                                src="https://tailwindui.com/img/ecommerce/icons/icon-gift-card-light.svg" alt="">
                        </div>
                    </div>
                    <div class="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                        <h3 class="text-base font-medium text-gray-900">All year discount</h3>
                        <p class="mt-3 text-sm text-gray-500">Looking for a deal? You can use the code
                            &quot;ALLYEAR&quot; at checkout and get money off all year round.</p>
                    </div>
                </div>
                <div class="text-center md:flex md:items-start md:text-left lg:block lg:text-center">
                    <div class="md:flex-shrink-0">
                        <div class="flow-root">
                            <img class="-my-1 mx-auto h-24 w-auto"
                                src="https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg" alt="">
                        </div>
                    </div>
                    <div class="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                        <h3 class="text-base font-medium text-gray-900">For the planet</h3>
                        <p class="mt-3 text-sm text-gray-500">We’ve pledged 1% of sales to the preservation and
                            restoration of the natural environment.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</main>