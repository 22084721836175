<vsf-layout>
    <vsf-layout-header>
        <header class="relative">
            <nav aria-label="Top">
                <!-- Top navigation -->
                <div class="bg-gray-900">
                    <div class="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                        <!-- Currency selector -->
                        <div></div>

                        <div class="flex items-center space-x-6">


                            <vsf-account-link
                                class="text-sm font-medium text-white hover:text-gray-100"></vsf-account-link>

                        </div>
                    </div>
                </div>

                <!-- Secondary navigation -->
                <div class="bg-white">
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div class="flex h-16 items-center justify-between">
                            <!-- Logo (lg+) -->
                            <div class="hidden lg:flex lg:flex-1 lg:items-center">
                                <a href="#">
                                    <span class="sr-only">Delia Jewelry</span>
                                    <img class="h-16 w-auto"
                                        src="https://shop.rovercloudspace.com/assets/preview/69/deliajewelry_logo-21__preview.png?preset=small"
                                        alt="">
                                </a>
                            </div>

                            <div class="hidden h-full lg:flex">
                                <!-- Flyout menus -->
                                <div class="inset-x-0 bottom-0 px-4">
                                    <div class="flex h-full justify-center space-x-8">
                                        <div class="flex">
                                            <div class="relative flex">
                                                <!-- Item active: "text-indigo-600", Item inactive: "text-gray-700 hover:text-gray-800" -->
                                                <button type="button" (click)="toggleShopByCategoryMenu()"
                                                    class="relative flex items-center justify-center text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800"
                                                    aria-expanded="false">
                                                    Shop by Product Type
                                                    <!-- Open: "bg-indigo-600", Closed: "" -->
                                                    <span
                                                        class="absolute inset-x-0 -bottom-px z-20 h-0.5 transition duration-200 ease-out"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </div>

                                            <!--
                            'Women' flyout menu, show/hide based on flyout menu state.
    
                            Entering: "transition ease-out duration-200"
                            From: "opacity-0"
                            To: "opacity-100"
                            Leaving: "transition ease-in duration-150"
                            From: "opacity-100"
                            To: "opacity-0"
                        -->
                                            <div *ngIf="isToggleShopByCategoryMenu"
                                                class="absolute inset-x-0 top-full z-10 bg-white text-sm text-gray-500">
                                                <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                                                <div class="absolute inset-0 top-1/2 bg-white shadow"
                                                    aria-hidden="true"></div>
                                                <!-- Fake border when menu is open -->
                                                <div class="absolute inset-0 top-0 mx-auto h-px max-w-7xl px-8"
                                                    aria-hidden="true">
                                                    <!-- Open: "bg-gray-200", Closed: "bg-transparent" -->
                                                    <div
                                                        class="h-px w-full bg-transparent transition-colors duration-200 ease-out">
                                                    </div>
                                                </div>

                                                <vsf-collections-menu class=" text-gray-600"></vsf-collections-menu>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Mobile menu and search (lg-) -->
                            <div class="flex flex-1 items-center lg:hidden">
                                <vsf-mobile-menu-toggle
                                    class="-ml-2 rounded-md bg-white p-2 text-gray-400"></vsf-mobile-menu-toggle>
                            </div>

                            <!-- Logo (lg-) -->
                            <a href="/" class="lg:hidden">
                                <span class="sr-only">Delia Jewelry</span>
                                <img src="https://shop.rovercloudspace.com/assets/preview/69/deliajewelry_logo-21__preview.png?preset=small"
                                    alt="" class="h-16 w-auto">
                            </a>
                            <div class="flex flex-1 items-center justify-end">
                                <div class="flex items-center lg:ml-8">
                                    <!-- Cart -->
                                    <div class="ml-4 flow-root lg:ml-8">
                                        <vsf-cart-toggle class="group -m-2 flex items-center p-2"
                                            (toggle)="openCartDrawer()"></vsf-cart-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </vsf-layout-header>
    <vsf-collections-menu-mobile [visible]="mobileNavVisible$ | async" class="z-10"></vsf-collections-menu-mobile>
    <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <footer aria-labelledby="footer-heading" class="mt-24 border-t bg-gray-50">
            <h2 class="sr-only" id="footer-heading">
                Footer
            </h2>
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <!-- <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Shop
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of topCollections$ | async">
                                        <a [routerLink]="['/category', item.slug]"
                                            class="text-base text-gray-500 hover:text-gray-600">
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-12 md:mt-0">
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Support
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.support">
                                        <a [href]="item.href" class="text-base text-gray-500 hover:text-gray-600">
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Company
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.company">
                                        <a [href]="item.href" class="text-base text-gray-500 hover:text-gray-600">
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <div class="mt-8 xl:mt-0">
                        <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                            Subscribe to our newsletter
                        </h3>
                        <p class="mt-4 text-base text-gray-500">
                            Be the first to know about exclusive offers & deals.
                        </p>
                        <form class="mt-4 sm:flex sm:max-w-md">
                            <label class="sr-only" htmlFor="email-address">
                                Email address
                            </label>
                            <input autoComplete="email"
                                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                id="email-address" name="email-address" placeholder="Enter your email" required
                                type="email" />
                            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                                    type="submit">
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="mt-8 border-t pt-8">

                </div>
            </div>
        </footer>
    </vsf-layout-footer>

</vsf-layout>