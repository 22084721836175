import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { gql } from 'apollo-angular';

import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { GetCollectionsQuery } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import {
    GetCollectionQuery,
    GetCollectionQueryVariables,
    SearchProductsQuery,
    SearchProductsQueryVariables
} from '../../../common/generated-types';
import { distinctUntilChanged, map, mapTo, scan, share, shareReplay, skip, switchMap, take, tap, } from 'rxjs/operators';
import { GET_COLLECTION, SEARCH_PRODUCTS } from '../product-list/product-list.graphql';

type SearchItem = SearchProductsQuery['search']['items'][number];

@Component({
    selector: 'vsf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {

    collections$: Observable<GetCollectionsQuery['collections']['items']>;
    heroImage: SafeUrl;
    products$: Observable<SearchItem[]>;
    totalResults$: Observable<number>;
    collection$: Observable<GetCollectionQuery['collection']>;
    facetValues: SearchProductsQuery['search']['facetValues'];
    unfilteredTotalItems = 0;
    activeFacetValueIds$: Observable<string[]>;
    searchTerm$: Observable<string>;
    displayLoadMore$: Observable<boolean>;
    loading$: Observable<boolean>;
    private currentPage = 0;
    private refresh = new BehaviorSubject<void>(undefined);
    constructor(private dataService: DataService) {
    }

    ngOnInit(): void {
        const perPage = 24;

        //const triggerFetch$ = combineLatest(this.collection$, this.activeFacetValueIds$, this.searchTerm$, this.refresh);
        const getInitialFacetValueIds = () => {
            combineLatest(this.collection$, this.searchTerm$).pipe(
                take(1),
                switchMap(([collection, term]) => {
                    return this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
                        input: {
                            term,
                            groupByProduct: true,
                            collectionId: collection?.id,
                            take: perPage,
                            skip: this.currentPage * perPage,
                        },
                    });
                }),
            ).subscribe(data => {
                this.facetValues = data.search.facetValues;
                this.unfilteredTotalItems = data.search.totalItems;
            });
        };
        this.loading$ = merge(
            // triggerFetch$.pipe(mapTo(true)),
        );
        // const queryResult$ = triggerFetch$.pipe(
        //     switchMap(([collection, facetValueIds, term]) => {
        //         return this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
        //             input: {
        //                 term,
        //                 groupByProduct: true,
        //                 collectionId: collection?.id,
        //                 facetValueFilters: facetValueIds.map(id => ({ and: id })),
        //                 take: perPage,
        //                 skip: this.currentPage * perPage,
        //             },
        //         }).pipe(
        //             tap(data => {
        //                 if (facetValueIds.length === 0) {
        //                     this.facetValues = data.search.facetValues;
        //                     this.unfilteredTotalItems = data.search.totalItems;
        //                 } else if (!this.facetValues) {
        //                     getInitialFacetValueIds();
        //                 } else {
        //                     this.facetValues = this.facetValues.map(fv => fv);
        //                 }
        //             }),
        //         );
        //     }),
        //     shareReplay(1),
        // );
        this.collections$ = this.dataService.query<GetCollectionsQuery>(GET_COLLECTIONS, {
            options: { take: 50 },
        }).pipe(map(({ collections }) => collections.items));

        this.heroImage = this.getHeroImageUrl();

        //Product stuff

        //const items$ = 
        this.products$ = this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
            input: {

                groupByProduct: true,

                take: perPage,
                skip: this.currentPage * perPage,
            },
        }).pipe(map(data => data.search.items));


        // this.totalResults$ = queryResult$.pipe(map(data => data.search.totalItems));
        this.displayLoadMore$ = combineLatest(this.products$, this.totalResults$).pipe(
            map(([products, totalResults]) => {
                return 0 < products.length && products.length < totalResults;
            }),
        );
    }

    private getHeroImageUrl(): string {
        const { apiHost, apiPort } = environment;
        return `${apiHost}:${apiPort}/assets/preview/74/homepage_hero_2__preview.jpg?preset=large`;
    }
    trackByProductId(index: number, item: SearchItem) {
        return item.productId;
    }

}



const GET_COLLECTIONS = gql`
    query GetCollections($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                id
                name
                description
                slug
                parent {
                    id
                    slug
                    name
                }
                featuredAsset {
                    id
                    preview
                }
            }
        }
    }
`;
